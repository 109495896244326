/**
 * All General Service will be here which wil globally overall project. 
 */
//Unique username service
InstaApp.factory('uniqueUsernameService', ['$http', function($http){
        var dataFactory = {}; 
        
        dataFactory.checkUniqueUsername = function(username, params){
            var queryUrl = "&"; 
            angular.forEach(params, function(value, key){
                queryUrl = queryUrl + escape(key) + "=" + escape(value) + "&"; 
            });
            return $http.get(SYSTEM_BASEURL + 'api/checkUniqueUser/?username='+escape(username)+queryUrl).then(
                    function(resp){
                        return resp.data.result; 
                    });
        };
        
        return dataFactory; 
        
        
}]);